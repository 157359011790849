import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

// const originalPush = VueRouter.prototype.push
// VueRouter.prototype.push = function push(location, onResolve, onReject) {
// if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
// return originalPush.call(this, location).catch(err => err)
// }

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    children:[
      {
        path:'/section1',
        name:'section1'
      },
      {
        path:'/section2',
        name:'section2'
      },
      {
        path:'/section3',
        name:'section3'
      },
      {
        path:'/section4',
        name:'section4'
      },
      {
        path:'/section5',
        name:'section5'
      },
    ]
  },

  //登录
  {
    path: '/Login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },

  //管理
  {
    path: '/Admin',
    name: 'Admin',
    component: () => import('../views/Admin/Admin.vue'),
    beforeEnter: (to, from, next) => {
      if (from.name !== 'Login') {next({ path: 'Login' })}
      else next()
    },
    children:[
      {
        path:'/addNews',
        name:'addNews',
        component: () => import('../views/Admin/addNews.vue'),
      },
      {
        path:'/deleteNews',
        name:'deleteNews',
        component: () => import('../views/Admin/deleteNews.vue'),
      },
      {
        path:'/reviseNews',
        name:'reviseNews',
        component: () => import('../views/Admin/reviseNews.vue'),
      },
    ],
  },

  //业务领域
  {
    path: '/1-1',
    name: 'page1-1',
    component: () => import('../views/BusinessAreas/page1-1.vue')
  },
  {
    path: '/1-2',
    name: 'page1-2',
    component: () => import('../views/BusinessAreas/page1-2.vue')
  },
  {
    path: '/1-3',
    name: 'page1-3',
    component: () => import('../views/BusinessAreas/page1-3.vue')
  },
  {
    path: '/1-4',
    name: 'page1-4',
    component: () => import('../views/BusinessAreas/page1-4.vue')
  },
  {
    path: '/1-5',
    name: 'page1-5',
    component: () => import('../views/BusinessAreas/page1-5.vue')
  },

  //典型案例
  {
    path: '/2-1',
    name: 'page2-1',
    component: () => import('../views/ClassicCase/page2-1.vue')
  },
  {
    path: '/2-2',
    name: 'page2-2',
    component: () => import('../views/ClassicCase/page2-2.vue')
  },
  {
    path: '/2-3',
    name: 'page2-3',
    component: () => import('../views/ClassicCase/page2-3.vue')
  },
  {
    path: '/2-4',
    name: 'page2-4',
    component: () => import('../views/ClassicCase/page2-4.vue')
  },
  {
    path: '/2-5',
    name: 'page2-5',
    component: () => import('../views/ClassicCase/page2-5.vue')
  },

  //服务与支持
  {
    path: '/3',
    name: 'page3',
    component: () => import('../views/Service&Support.vue')
  },

  //新闻动态
  {
    path: '/4',
    name: 'page4',
    component: () => import('../views/News/page4.vue')
  },
  {
    path:'/news/:newsid',
    name:'newsView',
    component:() => import('../views/News/newsView.vue'),
  },



  //关于我们
  {
    path: '/5-1',
    name: 'page5-1',
    component: () => import('../views/AboutUs/page5-1.vue')
  },
  {
    path: '/5-2',
    name: 'page5-2',
    component: () => import('../views/AboutUs/page5-2.vue')
  },
  {
    path: '/5-3',
    name: 'page5-3',
    component: () => import('../views/AboutUs/page5-3.vue')
  },
]

const router = new VueRouter({
  routes
})


export default router
