<template>
  <div class="home">
    <div id="QRcodeSpace">
      <QRcode @fullpageTop="parentScroll"></QRcode>
    </div>

    <full-page :options="options" ref="fullpage" class="fullpageShow">

      <div class="section">
        <div class="box1">
          <div class="carousel" style="cursor : pointer;">
            <el-carousel :interval="2500"  width="100%" :height=" bannerHeight + 'px' ">
              <el-carousel-item v-for="item in img" :key="item.index">
                <img id="imgShow" :src="item.url" @click="routerPush1(item.path)"/>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
      </div>

      <div class="section">
        <div class="box2">
          <div class="title1">
            <div class="titleHead">典型案例</div>
            <div class="titleMinHead">Classic case</div>
            <div class="titleHeadLine"></div>
            <div class="titleWord1">奉行与客户双赢的战略，追求完美</div>
            <div class="titleWord2">通过设计创新、集成创新和服务创新，不断超越用户期望</div>
          </div>
          <div id="boxBody1">
            <div class="imgCase1">
              <img class="imgCard1" src="../assets/card/智慧政府小图.jpg" @click="routerPush2('2-1')"/>
              <img class="imgCard1" src="../assets/card/智慧教育小图.jpg" @click="routerPush2('2-2')"/>
              <img class="imgCard1" src="../assets/card/智慧医疗小图.jpg" @click="routerPush2('2-3')"/>
            </div>
            <div class="imgCase2">
              <img class="imgCard2" src="../assets/card/智慧文旅小图.jpg" @click="routerPush2('2-4')"/>
              <img class="imgCard2" src="../assets/card/智慧小区小图.jpg" @click="routerPush2('2-3')"/>
              <img class="imgCard2" src="../assets/card/智慧金融小图.jpg" @click="routerPush2('2-2')"/>
              <img class="imgCard2" src="../assets/card/软件与服务小图.jpg" @click="routerPush2('2-5')"/>
            </div>
          </div>
        </div>
      </div>

      <div class="section">
        <div class="box3">
          <div class="title3">
            <div class="titleHead">新闻动态</div>
            <div class="titleMinHead">News</div>
            <div class="titleHeadLine"></div>
          </div>
          <div class="boxCenter1">
            <div class="imgBody1">
              <div v-for="(item) in newsImg" style="height: 40%; width: 90%;display: flex; flex-direction: column; margin: auto;margin-bottom: 10px ;position: relative;left: 0px;right: 0px;">
                <img class="imgCard" :src="'http://222.186.92.57:8081/'+item.newsimg"  @click="routerPush2('4')">
              </div>
            </div>
            <div style="width:65% ;height: 90%;position: relative;left: 20px;">
                <div id="boxBody2">
                  <table>
                  <tbody v-for="(item) in newsData" @click="goNews(item.newsnumber)" class="tableNews">
                    <tr><td>➣</td><td>{{item.newstitle}}</td><td>  </td><td>{{item.newsdays}}</td></tr>
                  </tbody>
                  </table>
                  <div id="titleHeadLineRight"></div>
                </div>
              <div id="lookMore1">
                <el-button type="info" @click="routerPush2('4')">查看更多➯</el-button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="section">
        <div class="box4">
          <div class="title1">
            <div class="titleHead" style="color:rgb(57, 57, 57);">关于我们</div>
            <div class="titleMinHead" style="color:rgb(57, 57, 57);">About Us</div>
            <div class="titleHeadLine" style="width:100px ; border-bottom: 3px rgb(57, 57, 57) solid;"></div>
          </div>
          <div class="boxCenter2">
            <div class="imgBody2">
              <img src="../assets/img/公司全景.jpg" id="img2" @click="routerPush2('5-1')">
            </div>
            <div style="width:45%">
              <div id="boxBody3">
                <span class="titleword6">江苏联盟信息工程有限公司创立于1996年，注册资本3520万元，位于镇江市丹徒新城长香东大道55号，占地30亩，建有办公大楼6000多平方，是一家集建筑智能化设计与施工、信息系统集成设计与施工、信息安全服务、IT运维服务、软件开发与销售为一体的高新技术企业。</span>
              </div>
              <div id="lookMore2">
                <el-button type="info" @click="routerPush2('5-1')">查看更多➯</el-button>
              </div>
            </div>
          </div>
          <div class="imgBody3">
            <div class="imgFlex">
              <div style="height:20%"><img class="imgCardMin" src="../assets/card/服务能力.png"></div>
              <div class="cardWordBody1">
                <span class="cardWord1">服务能力</span>
                <span class="cardWord2">长期服务客户400+</span>
              </div>
            </div>
            <div class="imgFlex">
              <div style="height:20%"><img class="imgCardMin" src="../assets/card/客户满意度.png"></div>
              
              <div class="cardWordBody1">
                <span class="cardWord1">客户满意度</span>
                <span class="cardWord2">99%</span>
              </div>
            </div>
            <div class="imgFlex">
              <div style="height:20%"><img class="imgCardMin" src="../assets/card/智慧项目.png"></div>
              <div class="cardWordBody1">
                <span class="cardWord1">智慧项目</span>
                <span class="cardWord2">3500+</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="section">
        <div class="box5">
          <div class="title4">
            <img id="img3" src="../assets/img/服务与支持热线.jpg">
            <div class="titleWord3">“一切以用户为中心”是我们的宗旨</div>
            <div class="titleWord4">以用户需求为己任，以一流的技术、一流的质量、一流的敬业精神，为用户提供一流的服务，是我们始终如一的追求。</div>
          </div>
          <div style="top: 5%; position: relative; height: 30%;">
            <bottomMenu style="height:100%;"></bottomMenu>
          </div>
        </div>
      </div>
    </full-page>
    
  </div>
</template>

<script>
import bottomMenu from '@/components/bottomMenu.vue';
import QRcode from '@/components/QRcode.vue';
import router from '@/router';
import http from '../service/index';

export default {

  name: "Home",
  components: {
    bottomMenu,
    QRcode,
  },
  data() {
    return {
      newsData:'',
      newsImg:'',
      newsnumber:'',

      bannerHeight : 0,
      screenWidth :0,

      options: {
        licenseKey: "OPEN-SOURCE-GPLV3-LICENSE",
        afterLoad: this.afterLoad,
        scrollBar: false,
        navigation: true,
        recordHistory: true,
        navigationColor:'blue',
        navigationPosition: 'left',
        anchors: ['section1','section2','section3','section4','section5'], 
        
      },

      img: [
        { url: require("../assets/img/江苏联盟大门.jpg"),path:'null'},
        { url: require("../assets/img/智慧建筑.jpg"),path:'1-1'},
        { url: require("../assets/img/信息系统集成.jpg"),path:'1-2'},
        { url: require("../assets/img/信息安全.jpg"),path:'1-3'},
        { url: require("../assets/img/IT运维.jpg"),path:'1-4'},
        { url: require("../assets/img/软件开发.jpg"),path:'1-5'},
      ],
    };
  },
  methods: {
    parentScroll() {
      this.$refs.fullpage.api.moveTo('section1')
    },

    routerPush1(path){
      if(path === 'null'){
        return
      }
      router.push({path})
    },
    routerPush2(path){
      router.push({path})
    },

    goNews(newsRouter){
      router.push({ path: `/news/${newsRouter}` })
    }
  },
  beforeCreate(){
    http.post('/showNews').then
    (response => {
      this.newsData = response.data.slice(0,9);
      this.newsImg = this.newsData.slice(0,2);
      this.newsData.forEach(element => {
        if(element.newstitle.length >= 18){ 
          element.newstitle=element.newstitle.substring(0,17) + '...'
        }
      });
    })
  },
  mounted(){
    this.screenWidth =  window.innerWidth;
    this.bannerHeight = 40 / 100 * this.screenWidth;
  },
  
};
</script>

<style scoped>
#imgShow {
  height: 100%;
  width: 100%;
}

.carousel {
  position: absolute;
  top: 10%;
  width: 75%;
  height: 75%;
  left: 0px;
  right: 0px;
  margin: auto;
}

.box1{
  width: 100%;
  height: 100%;
  background-image: url(../assets/img/滑块背景1.jpg);
  display: flex;
  object-fit: cover;
}
.box2 {
  width: 100%;
  height: 100%;
  background-image: url(../assets/img/滑块背景2.jpg);
  object-fit: cover;
}
.box3 {
  width: 100%;
  height:100%;
  background-image: url(../assets/img/滑块背景3.jpg);
  object-fit: cover;
}
.box4 {
  width: 100%;
  height:100%;
  background-image: url(../assets/img/滑块背景2.jpg);
  object-fit: cover;
}
.box5 {
  width: 100%;
  height:100%;
  left: 0px;
  right: 0px;
  margin: auto;
  background-image: url(../assets/img/滑块背景2.jpg);
  object-fit: cover;
}

.title1 {
  top: 10%;
  left: 0px;
  right: 0px;
  position: relative;
  margin: auto;
  text-align: center;
}
.title3 {
  top: 10%;
  height: 15%;
  left: 0px;
  right: 0px;
  position: relative;
  margin: auto;
  text-align: center;
}
.title4 {
  left: 0px;
  right: 0px;
  width: 100%;
  height: 65%;
  position: relative;
  display: flex;
  margin: auto;
  flex-direction: column;
}

.titleHead {
  font-size: 40px;
  font-weight: 500;
  color: black;
  position: relative;
  margin: auto;
}
.titleMinHead {
  font-size: 20px;
  color: black;
  position: relative;
  margin: auto;
}
.titleHeadLine {
  top: 90px;
  left: 8px;
  right: 8px;
  position: absolute;
  margin: auto;
  width: 300px;
  border-bottom: 3px rgb(106, 106, 106) solid;
}

.titleWord1 {
  font-size: 20px;
  color: black;
  top: 100px;
  position: absolute;
  margin: auto;
  width: 500px;
  left: 0px;
  right: 0px;
}
.titleWord2 {
  font-size: 20px;
  color: black;
  top: 125px;
  position: absolute;
  margin: auto;
  width: 500px;
  left: 0px;
  right: 0px;
}
.titleWord3 {
  font-size: 2.5vw;
  font-weight: 500;
  color: rgb(40, 79, 249);
  position: relative;
  left: 0px;
  right: 0px;
  margin: auto;
  top: 5%;
  width: 70%;
}
.titleWord4 {
  font-size: 2vw;
  line-height: 50px;
  text-indent: 2em;
  color: black;
  left: 0px;
  right: 0px;
  margin: auto;
  top: 5%;
  position: relative;
  width: 70%;
}

.cardWord1{
  color: black;
  font-size: 1.4vw;
  display: block;
}
.cardWord2{
  color: black;
  font-size: 1vw;
  display: block;

}

#img1{
  top:250px;
  position: absolute;
  left: 750px;
  height: 375px;
}
#img2{
  height:100%;
  width: 100%;
  border-radius :5px; 
}
#img3{
  position: relative;
  top: 5%;
  width: 100%;
  height:50%;
  left: 0px;
  right: 0px;
  margin: auto;
}

.boxCenter1{
  width: 80%;
  height: 80%;
  top: 12%;
  left: 0px;
  right: 0px;
  bottom: 5%;
  position: relative;
  margin: auto;
  display: flex;
  justify-content:center;
}
.boxCenter2{
  width: 100%;
  height: 80%;
  top: 20%;
  left: 0px;
  right: 0px;
  margin: auto;
  position: relative;
  display: flex;
  justify-content: center
}


#boxBody1 {
  top: 25%;
  width: 100%;
  height: 80%;
  text-align: center;
  position: relative;
  left: 0px;
  right: 0px;
  margin: auto;
}
#boxBody2 {
  font-size: 1.7vw;
  color: black;
  width: 90%;
  left: 0px;
  right: 0px;
  margin: auto;
  position: relative;
}
#boxBody3 {
  font-size: 1.7vw;
  color: rgb(0, 0, 0);
  text-indent: 2em;
  width: 100%;
  top: 40px;
  position:relative;
  margin-left:50px ;
}

.imgCase1{
  width: 100%;
  height: 40%;
}
.imgCase2{
  width: 100%;
  height: 40%;
}

.imgBody1{
  width: 35%;
  height: 90%;
  position: relative;
}
.imgBody2{
  width: 30%;
  height:50%;
  position:relative;
  cursor : pointer;
}
.imgBody3{
  top: 77.5%;
  left: 100px;
  right: 0px;
  width: 100%;
  height: 100%;
  margin: auto;
  position: absolute;
  display: flex;
}

.cardWordBody1{
  position: relative;
  top: 25px;
  margin-right: 100px ;
}

#lookMore1{
  top:20px;
  left: 25%;
  right: 0px;
  margin: auto;
  width: 20%;
  position: relative;
}
#lookMore2{
  top:70px ;
  width: 20%;
  left: 25%;
  right: 0px;
  margin: auto;
  position: relative;
}

.imgCard {
  width: 95%;
  height: 100%;
  cursor : pointer;
  display:block;
}
.imgCard1 {
  height: 80%;
  width: 20%;
  margin: 10px;
  cursor : pointer;
}
.imgCard2 {
  height: 80%;
  width: 20%;
  margin: 10px;
  cursor : pointer;
}
.imgCardMin{
  height: 100%;
}
.imgFlex{
  display: flex;
  width: 30%;
  height: 100%;
}

.tableNews{
  cursor : pointer;
}
.tableNews:hover{
  color: rgb(0, 16, 187);
}

</style>
