<template>

    <div id="head">
      <div id="logo"><img src="../assets/logo/logoName.jpg" height="100%"></div>
      <div id="menu">
          <el-menu class="el-menu-demo" mode="horizontal" :unique-opened='true' :default-active="$route.path"
        background-color="#fff" text-color="black" active-text-color="#0040FF" router>

            <el-menu-item index="/" class="el-menu1"><div class="word2">首页</div></el-menu-item>

            <el-submenu index="1" class="el-menu2">
              <template slot="title" class="menuflex"><div class="word1">业务领域</div></template>
              <el-menu-item index="/1-1">智慧建筑</el-menu-item>
              <el-menu-item index="/1-2">信息系统集成</el-menu-item>
              <el-menu-item index="/1-3">信息安全服务</el-menu-item>
              <el-menu-item index="/1-4">IT运维服务</el-menu-item>
              <el-menu-item index="/1-5">软件与服务</el-menu-item>
            </el-submenu>

            <el-submenu index="3" class="el-menu2">
              <template slot="title" class="menuflex"><div class="word1">典型案例</div></template>
              <el-menu-item index="/2-1">智慧政府——公检法</el-menu-item>
              <el-menu-item index="/2-1">智慧政府</el-menu-item>
              <el-menu-item index="/2-2">智慧教育</el-menu-item>
              <el-menu-item index="/2-2">智慧金融</el-menu-item>
              <el-menu-item index="/2-3">智慧医疗</el-menu-item> 
              <el-menu-item index="/2-3">智慧小区</el-menu-item>
              <el-menu-item index="/2-4">智慧文旅</el-menu-item>
              <el-menu-item index="/2-5">软件与服务</el-menu-item>
            </el-submenu>

            <el-menu-item index="/3" class="el-menu1"><div class="word2">服务与支持</div></el-menu-item>

            <el-menu-item index="/4" class="el-menu1"><div class="word2">新闻动态</div></el-menu-item>

            <el-submenu index="5" class="el-menu2">
              <template slot="title" class="menuflex"><div class="word1">关于我们</div></template>
              <el-menu-item index="/5-1">联盟简介</el-menu-item>
              <el-menu-item index="/5-2">人才招聘</el-menu-item>
              <el-menu-item index="/5-3">联系我们</el-menu-item>
            </el-submenu>

          </el-menu>
      </div>
    </div>

</template>

<script>

export default {
  name: 'Menu',
  props: {
    
  },
}
</script>

<style scoped>
#head{
  background-color: white;
  height: 60px;
  width: 100%;
  left: 0px;
  right: 0px;
  display: flex;
  position:absolute;
  padding:0px;
  margin:auto;
  z-index:9999;
}

#logo{
position:relative;
height: 80%;
top: 5px;
margin: auto;
}

#menu{
  position:relative;
  top: 0px;
  width: 85%;
  margin: auto;
}

.el-menu-demo{
  display: flex;
  width: 100%;
}

.el-menu1{
  width: 16%;
  margin: auto;
  text-align: center;
}
.el-menu2{
  width: 16%;
  margin: auto;
  text-align: center;
}
.router-link-active{
  text-decoration: none;
}
.word1{
  font-size:1.5vw;
  margin: auto;
  position:relative;
  display: inline;
}

.word2{
  font-size:1.5vw;
  margin: auto;
  position:relative;
}





</style>
