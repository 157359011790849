import Vue from 'vue'
import App from './App.vue'
import router from './router'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import VueFullpage from 'vue-fullpage.js';
import 'fullpage.js/dist/fullpage.css';




Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(VueFullpage);




new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
