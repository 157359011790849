<template>
  <div id="app">
    <div style="position: relative;"><Menu></Menu></div>
    <div id="homepage">
      <router-view />
    </div>
  </div>
</template>

<script>
import Menu from "@/components/Menu.vue";

export default {
  components: {
    Menu,
  },
};
</script>

<style>
html, body, #app{
  height: 100%;
}

body #fp-nav ul li{
  height: 35px;
}
body #fp-nav ul li a span{
  background: rgb(0, 75, 174);
  height: 4px;
  width: 4px;
}

</style>
