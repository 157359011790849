<template>
    <div>
        <div class="QRcode">
            <div class="QRcodeShow" @mouseenter="show1 =!show1"  @mouseleave="show1 =!show1" >
                <img style="top:20px; position: relative; height:30px;" src="../assets/minimg/wechat.png">
                <div style="top:20px; position: relative; font-size:10px; color:white;">微信公众号</div>
            </div>
            <div class="numberShow" @mouseenter="show2 =!show2"  @mouseleave="show2 =!show2">
                <img style="top:20px; position: relative; height:30px;" src="../assets/minimg/phone.png">
                <div style="top:20px; position: relative; font-size:10px; color:white;">联系我们</div>
            </div>
            <div class="backTop" @click="toTop">
                <i class="el-icon-top" style="top:20px; position: relative; font-size:35px;"></i>
                <div style="top:20px; position: relative; font-size:10px; color:white;">回到顶部</div>
            </div>
            

                <transition name="el-fade-in-linear">
                    <div class="QRcodeShow1" v-show="show1" @mouseenter="show1 =!show1"  @mouseleave="show1 =!show1">
                        <img style="height:200px; top:5px; position: relative;" src="../assets/img/江苏联盟二维码.jpg">
                    </div>
                </transition>

                <transition name="el-zoom-in-center">
                    <div class="numberShow1" v-show="show2" @mouseenter="show2 =!show2"  @mouseleave="show2 =!show2">
                        <div style="top:22px; position: relative; font-size:25px; color:white;">0511-84408730</div>
                    </div>
                </transition>

        </div>
    </div>
</template>

<script>

 

export default {
    name: 'QRcode',
    props: {
    
    },

    data: () => ({
      show1: false,
      show2: false
    }),

    methods: {

        toTop() {
            document.documentElement.scrollTop = 0;
            this.$emit('fullpageTop')
        },

    },

}
</script>

<style scoped>
.QRcode{
    position:fixed;
    z-index:1;
    right: 15px;
    top: 230px;
    margin: auto;
    width: 70px;
    height: 244px;
    background-color: rgb(0, 75, 174);
}

.QRcodeShow{
    text-align: center;
    width: 100%;
    height: 80px;
    border-bottom: 2px solid white;
}

.numberShow{
    text-align:center;
    width: 100%;
    height: 80px;
    border-bottom: 2px solid white;
}

.backTop{
    text-align:center;
    width: 100%;
    height: 80px;
}

.QRcodeShow1{
    position: absolute;
    right: 75px;
    width: 210px;
    height: 210px;
    top: 0px;
    background-color: rgb(0, 75, 174);
    text-align:center;
}

.numberShow1{
    position: absolute;
    right: 75px;
    width: 210px;
    height: 80px;
    top: 82px;
    background-color: rgb(0, 75, 174);
    text-align:center;
}


</style>
