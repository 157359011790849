<template>
    <div id="bottomMenubody">
        <div style="display:flex;width: 100%;height: 100%;">
            <div class="menuWord">
                <ul type="none" class="menuUl">
                    <li class="menuTitle">业务领域</li>
                    <li><router-link to="/1-1">智慧建筑</router-link></li>
                    <li><router-link to="/1-2">信息系统集成</router-link></li>
                    <li><router-link to="/1-3">信息安全服务</router-link></li>
                    <li><router-link to="/1-4">IT运维服务</router-link></li>
                    <li><router-link to="/1-5">软件与服务</router-link></li>
                </ul>
            </div>
            <div class="menuWord" >
                <ul type="none" class="menuUl">
                    <li class="menuTitle">新闻动态</li>
                    <li><router-link to="/4">联盟新闻</router-link></li>
                </ul>
            </div>
            <div class="menuWord">
                <ul type="none" class="menuUl">
                    <li class="menuTitle">关于我们</li>
                    <li><router-link to="/5-1">联盟简介</router-link></li>
                    <li><router-link to="/5-2">人才招聘</router-link></li>
                    <li><router-link to="/5-3">联系我们</router-link></li>
                </ul>
            </div>
            <div class="menuCall">
                <ul type="none" style="width:100%;">
                    <li class="menuTitle" style="text-align: center; width: 100%;">联系方式</li>
                    <table style="font-size:1.5vw; color:rgb(145, 170, 201);text-align: center;" >
                        <tr><td style="width:30%">电话：</td><td  style="width:70%">0511-84408730</td></tr>
                        <tr><td>邮箱：</td><td>netman_hr@126.com</td></tr>
                        <tr><td>地址：</td><td>江苏省镇江市丹徒区长香东大道55号</td></tr>
                    </table>
                </ul>
            </div>
            <div class="menuMig">
                <img src="../assets/img/江苏联盟二维码.jpg" style="height:60%; width: 60%; margin-top:20px;">
                <div style="color:rgb(145, 170, 201); text-align:center;">江苏联盟官方公众号</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'bottomMenu',
  props: {
    
  },
}
</script>

<style scoped>
#bottomMenubody{
    width: 100%;
    height: 30%;
    position: relative;
    background-color: rgb(0, 75, 174);
    border-top: 2px solid rgb(154, 154, 154);
}

.menuWord{
    position: relative;
    justify-content: center;
    text-align: center;
    width: 15%;
    
}


.menuCall{
    margin-left: 5% ;
    width: 25%;
}

.menuTitle{
    width: 100%;
    color: white;
    font-size: 1.7vw;
}

.menuMig{
    text-align: center;
    width: 20%;
    height: 100%;
    margin-left: 5%;
    font-size: 1.2vw;
}

li{
    color: rgb(145, 170, 201);
    font-size: 1.5vw;
}

a{
  text-decoration: none;
  color: rgb(145, 170, 201);
	}

.router-link-active {
    text-decoration: none;
    color: rgb(255, 255, 255);
  }
</style>