// const baseurl = 'http://localhost:8081/'

// const baseurl = 'http://172.16.1.6:8081/'
const baseurl = 'http://222.186.92.57:8081/'


import axios from "axios";

const http =  axios.create({
    baseURL:baseurl
})

export default http;

// http.interceptors.request.use(function(config){
//     config.headers['token']='token'
//     console.log('config',config)

//     return config;

// },function(error){

//     return Promise.reject(error);

// })
